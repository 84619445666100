import React from 'react';
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Marker,
} from 'react-simple-maps';
import { Paper } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useFetchApi } from '../utils/UseFetchApi'
import { IClusterInfo } from './Clusters';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

const wrapperStyles = {
    width: "100%",
    maxWidth: 900,
    margin: "0 auto",
}

export interface ICoordinate {
    longitude: number;
    latitude: number;
}

export interface IClusterLocation {
    location: string;
    id: string;
    coordinate: ICoordinate;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            height: "100%",
            //width: "100%"
        }
    }),
);

export default function ClusterMap() {
    const classes = useStyles();
    const clustersInfo = useFetchApi<IClusterInfo[]>(window.location.origin + "/api/v1/clusters");
    const clustersLocation = useFetchApi<IClusterLocation[]>(window.location.origin + "/api/v1/clusters/locations");
    const history = useNavigate();

    function handleClick(marker: string) {
        history('/admin/clusters');
    }

    function getClusterColor(location: string) {
        if (clustersInfo.data && clustersInfo.data.find(cluster => cluster.location === location))
            return "#67CC22";
        return "#FF0000";
    }

    React.useEffect(() => {
        clustersInfo.run();
        clustersLocation.run();
    }, []);

    return (
        <Paper className={classes.paper}>
            <div style={wrapperStyles}>
                <ComposableMap
                    projectionConfig={{
                        scale: 160
                    }}

                    style={{
                        width: "100%",
                        //height: "auto",
                        maxHeight: "300px"
                    }}>
                    <ZoomableGroup center={[0, 20]} zoom={1.5} disablePanning>
                        <Geographies geography="/world-continents.json">
                        {({ geographies }) =>
                                geographies.map(
                                    (geography: any, i: number) => geography.id !== "ATA" &&
                                    (
                                        <Geography
                                            key={i}
                                            geography={geography}
                                            style={{
                                                default: {
                                                    fill: "#ECEFF1",
                                                    stroke: "#607D8B",
                                                    strokeWidth: 0.75,
                                                    outline: "none",
                                                },
                                                hover: {
                                                    fill: "#ECEFF1",
                                                    stroke: "#607D8B",
                                                    strokeWidth: 0.75,
                                                    outline: "none",
                                                },
                                                pressed: {
                                                    fill: "#ECEFF1",
                                                    stroke: "#607D8B",
                                                    strokeWidth: 0.75,
                                                    outline: "none",
                                                },
                                            }} />
                                    ))}
                        </Geographies>
                        {clustersLocation.data && clustersLocation.data.map((dataCenter: IClusterLocation, i: number) => (
                            <Marker
                                key={i}
                                coordinates={[dataCenter.coordinate.longitude, dataCenter.coordinate.latitude]}
                                onClick={() => handleClick(dataCenter.location)}
                                style={{
                                    default: { fill: "#EEF6E8" },
                                    hover: { fill: getClusterColor(dataCenter.location) },
                                    pressed: { fill: getClusterColor(dataCenter.location) }
                                }}>
                                <circle
                                    cx={0}
                                    cy={0}
                                    r={7}
                                    style={{
                                        fill: getClusterColor(dataCenter.location),
                                        stroke: "#607D8B",
                                        strokeWidth: 0.75,
                                        opacity: 0.9,
                                    }} />
                            </Marker>
                        ))}
                    </ZoomableGroup>
                </ComposableMap>
            </div>
        </Paper>
    );
}
