export function toArray<Type>(maybeJobs: Type | Type[]): Type[] {
    return Array.isArray(maybeJobs) ? maybeJobs : [maybeJobs];
}

export function downloadUri(uri: string) {
    const link = document.createElement('a');
    link.setAttribute("href", uri);
    link.target = "_blank";
    link.setAttribute("download", "");
    link.click();
}

export function downloadUris(uris: string[]) {
    uris.forEach(uri => {
        downloadUri(uri);
    })
}

export function arrayUnique(array: any[]) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}
