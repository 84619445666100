import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
//:>--------------------------------------------------------------------------------------+
//:>
//:>  $Source: InvalidJobsFromPools.tsx $
//:>
//:>  $Copyright: (c) 2017 Bentley Systems, Incorporated. All rights reserved. $
//:>
//:>+--------------------------------------------------------------------------------------
import * as React from 'react';
import MaterialTable from "@material-table/core";
import { useFetchApi } from '../utils/UseFetchApi';
import { useSnackbar } from 'notistack';
import { Grid, styled, Tooltip, Typography } from '@mui/material';
import { createDateColumn } from '../utils/DateUtils';
import { Selector } from "./Selector";
import { InfoSharp } from '@mui/icons-material';
import { ConfirmationDialog } from './ConfirmationDialog';
const MaxBulkJobsTerminate = 20;
const allItemsOption = {
    value: "All",
    label: "All"
};
const TableContainer = styled('div')({
    padding: 16,
    // Required to display the location filter with title
    '> div.MuiPaper-root > div.MuiToolbar-root > div.MuiBox-root:first-child': {
        flex: 1
    },
    '> div.MuiPaper-root > div.MuiToolbar-root > div.MuiBox-root:nth-child(2)': {
        flex: 0
    }
});
export function InvalidJobsFromPools(props) {
    var _a, _b, _c, _d;
    const invalidJobReport = useFetchApi(props.getInvalidJobReportUrl);
    const fetchApi = useFetchApi();
    const [isJobDeleted, setJobDeleted] = React.useState(false);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [location, setLocation] = React.useState((_b = (_a = props.defaultlocationFilterOptions) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : allItemsOption.value);
    const [jobToDelete, setJobToDelete] = React.useState(undefined);
    React.useEffect(() => {
        if (invalidJobReport.fetchCount === 0 || isJobDeleted) {
            invalidJobReport.run();
            setJobDeleted(false);
        }
    }, [isJobDeleted]);
    function getData() {
        var _a;
        var jobs = (_a = invalidJobReport.data) !== null && _a !== void 0 ? _a : [];
        if (location.toString() !== allItemsOption.value) {
            jobs = jobs.filter(j => j.location.toString() === location.toString());
        }
        return jobs;
    }
    //Currently this is not enabled. We will not show the confirmation dialog.
    function showTerminateConfirmation(job) {
        setJobToDelete(job);
        setIsConfirmationDialogOpen(true);
    }
    //Confirmation dialog closed.
    function handleConfirmationCancel() {
        setJobToDelete(undefined);
        setIsConfirmationDialogOpen(false);
    }
    //Confirmed termination.
    function handleConfirmationOk() {
        setIsConfirmationDialogOpen(false);
        handleTerminate(jobToDelete);
        setJobToDelete(undefined);
    }
    function handleTerminate(job) {
        const jobs = job ? [job] : invalidJobReport.data;
        const jobsToTerminate = jobs === null || jobs === void 0 ? void 0 : jobs.slice(0, MaxBulkJobsTerminate);
        if (!jobsToTerminate || jobsToTerminate.length === 0)
            return;
        enqueueSnackbar(`Terminating ${jobsToTerminate.length} job(s)...`, {
            variant: "info"
        });
        const terminateRequests = jobsToTerminate.map((j) => {
            const url = props.getCancelJobUrl(j);
            return fetchApi.run(url, {
                method: "POST"
            });
        });
        Promise.allSettled(terminateRequests).then((resp) => {
            const terminatedCount = resp.filter((r) => r.status === "fulfilled").length;
            enqueueSnackbar(`${terminatedCount} out of ${jobsToTerminate.length} job(s) terminated.`, {
                variant: terminatedCount === jobsToTerminate.length
                    ? "success"
                    : terminatedCount === 0
                        ? "error"
                        : "warning"
            });
            setJobDeleted(true);
        });
    }
    function onLocationChanged(location) {
        setLocation(location);
    }
    function getTitleWithFilter() {
        const locationOptions = [
            allItemsOption,
            ...props.locationFilterOptions
        ];
        return (_jsxs(Grid, { container: true, justifyContent: 'space-between', children: [_jsx(Grid, { item: true, xs: 'auto', children: _jsxs(Typography, { variant: "h6", children: ["Invalid jobs", _jsx(Tooltip, { title: "Jobs active in clusters, but not in DB", children: _jsx(InfoSharp, { fontSize: 'small', style: { marginLeft: '0.4rem', fontSize: 15 } }) })] }) }), _jsx(Grid, { item: true, xs: 'auto', children: _jsx(Selector, { default: allItemsOption, onValueChanged: onLocationChanged, options: locationOptions }) })] }));
    }
    return (_jsxs("div", { children: [_jsx(TableContainer, { children: _jsx(MaterialTable, { title: getTitleWithFilter(), isLoading: invalidJobReport.isFetching, columns: [
                        { title: "Location", field: "location" },
                        { title: "Job Id", field: "id" },
                        { title: "Name", field: "name" },
                        { title: "Cluster", field: "executionInfo.poolId" },
                        { title: "DB state", field: "dbState" },
                        createDateColumn("Creation time", "creationTime"),
                        createDateColumn("Start time", "executionInfo.startTime")
                    ], data: getData(), actions: [
                        {
                            tooltip: `Terminate ${Math.min(MaxBulkJobsTerminate, (_d = (_c = invalidJobReport.data) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0)} job(s)`,
                            icon: 'playlist_remove',
                            isFreeAction: true,
                            hidden: true,
                            onClick: () => handleTerminate()
                        },
                        {
                            tooltip: "Refresh",
                            icon: 'refresh',
                            isFreeAction: true,
                            onClick: () => invalidJobReport.run()
                        },
                        rowData => ({
                            tooltip: 'Terminate job',
                            icon: 'cancel',
                            onClick: (_, rowData) => handleTerminate(rowData)
                        })
                    ], options: {
                        actionsColumnIndex: 0,
                        headerStyle: { backgroundColor: '#9BA5AE', color: '#000000' },
                        search: true,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30, 40, 50],
                        padding: "dense",
                        rowStyle: {
                            fontSize: "0.875rem",
                        }
                    } }) }), isConfirmationDialogOpen &&
                _jsx(ConfirmationDialog, { isOpen: isConfirmationDialogOpen, title: "Confirm termination", content: _jsxs(_Fragment, { children: ["Are you sure you want to terminate job: ", _jsx("b", { children: jobToDelete === null || jobToDelete === void 0 ? void 0 : jobToDelete.name }), " id:", _jsx("b", { children: jobToDelete === null || jobToDelete === void 0 ? void 0 : jobToDelete.id }), "?"] }), okButtonText: "Terminate", onOkClicked: handleConfirmationOk, cancelButtonText: "Close", onCancelClicked: handleConfirmationCancel })] }));
}
