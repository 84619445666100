import * as React from 'react';
import { useSnackbar } from 'notistack';
import { useReactOidc } from '@axa-fr/react-oidc-context';

async function handleErrors(response: Response) {
    if (!response.ok) {
        let errText = `${response.status} : ${await response.text()}`;
        throw Error(errText);
    }
    return response;
}

export function useFetchApi<T>(url?: RequestInfo, init?: RequestInit) {

    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = React.useState<T>();
    const [response, setResponse] = React.useState<Response>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [fetchCount, setFetchCount] = React.useState(0);
    const [error, SetError] = React.useState<any>(undefined);
    const { oidcUser } = useReactOidc();

    function buildRequest(init?: RequestInit) {
        if (init)
            return injectToken(init);

        return injectToken({ method: 'GET' });
    }

    function injectToken(options: RequestInit) {
        const update = { ...options };
        update.headers = {
            ...update.headers,
            Authorization: `Bearer ${oidcUser.access_token}`,
        };
        return update;
    }

    function run(newUrl?: RequestInfo, newInit?: RequestInit): Promise<T | undefined> {
        console.log("Fetching...");

        let fetchUrl = newUrl ? newUrl : url;
        if (!fetchUrl) {
            SetError("Missing fetch URI");
            return Promise.reject("Missing fetch URI");
        }

        setIsLoading(true);
        SetError(undefined);
        return fetch(fetchUrl, buildRequest(newInit ? newInit : init))
            .then(res => { setResponse(res); return res; })
            .then(res => handleErrors(res))
            .then(res => {
                if (res.status !== 204) {
                    return res.json();
                }
                return undefined
            })
            .then(d => {
                setData(d);
                return d;
            })
            .catch(err => {
                enqueueSnackbar(`Failed to fetch data: ${err}`, { variant: "error" })
                SetError(err);
                return undefined;
            })
            .finally(() => {
                setIsLoading(false);
                setFetchCount(fetchCount => fetchCount + 1);
            });
    }

    return {
        response,
        data,
        run,
        hasData: typeof data !== "undefined",
        isFetching: isLoading,
        fetchCount,
        error
    }
}
